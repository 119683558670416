import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ListItem, NumberedList, Link } from '@entur/typography';
import '../../sales/guides.scss';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "transport-operator-guide"
    }}>{`Transport operator guide`}</h1>
    <p>{`This is a guide for how transport operators can make their products available for sale through the Entur Sales Platform.`}</p>
    <p>{`All transport operators who wish to make their products available for sale through our platform must support the TOMP API standard.`}</p>
    <NumberedList className="to-guides-toc" mdxType="NumberedList">
    <ListItem mdxType="ListItem">
        <Link href="#tomp-a-europeen-api-standard" mdxType="Link">
            TOMP: A Europeen API standard
        </Link>
        <NumberedList className="to-guides-toc" mdxType="NumberedList">
            <ListItem mdxType="ListItem">
                <Link href="#what-is-the-tomp-standard" mdxType="Link">What is the TOMP standard</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#why-did-we-choose-this-standard" mdxType="Link">Why did we choose this standard</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#why-integrating-with-the-tomp-standard-is-a-smart-business-move" mdxType="Link">
                    Why Integrating with the TOMP Standard is a Smart Business Move
                </Link>
                <NumberedList className="to-guides-toc" mdxType="NumberedList">
                    <ListItem mdxType="ListItem">
                        <Link href="#why-the-tomp-standard-is-the-right-choice-and-what-it-offers" mdxType="Link">
                            Why the TOMP Standard is the Right Choice and What It Offers
                        </Link>
                    </ListItem>
                </NumberedList>
            </ListItem>
        </NumberedList>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#our-reference-application" mdxType="Link">
            Our reference application
        </Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#realtime-data" mdxType="Link">
            Realtime data
        </Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#implementation" mdxType="Link">
            Implementation
        </Link>
    </ListItem>
    </NumberedList>
    <h2 {...{
      "id": "tomp-a-europeen-api-standard"
    }}>{`TOMP: A Europeen API standard`}</h2>
    <p>{`TOMP = Transport Operator MaaS Provider.`}</p>
    <h3 {...{
      "id": "what-is-the-tomp-standard"
    }}>{`What is the TOMP standard`}</h3>
    <p>{`TOMP (Transport Operator to Mobility-as-a-Service Provider) is a European API standard designed to enable seamless integration
and communication between transport operators and Mobility-as-a-Service (MaaS) providers. It allows for functions like booking, payment,
and real-time data sharing, helping to create a unified, user-friendly mobility experience across Europe by connecting various transport services
within a single application.`}</p>
    <ul>
      <li parentName="ul">{`Read more about TOMP here: `}<a parentName="li" {...{
          "href": "https://github.com/TOMP-WG"
        }}>{`https://github.com/TOMP-WG`}</a></li>
      <li parentName="ul">{`The TOMP swagger doc: `}<a parentName="li" {...{
          "href": "https://app.swaggerhub.com/apis-docs/TOMP-API-WG/transport-operator_maas_provider_api/1.5.0#/"
        }}>{`https://app.swaggerhub.com/apis-docs/TOMP-API-WG/transport-operator_maas_provider_api/1.5.0#/`}</a></li>
    </ul>
    <h3 {...{
      "id": "why-did-we-choose-this-standard"
    }}>{`Why did we choose this standard`}</h3>
    <p>{`As a public transport company, we want to use standards to avoid 1-1 implementations between different services.
The TOMP API standard is a well-established standard with over 40 users spread over various countries in Europe and is a
standard based on `}<a parentName="p" {...{
        "href": "https://gbfs.org/"
      }}>{`GBFS`}</a>{` data. Since almost all data provided by shared mobility actors is based on GBFS data,
this also makes the implementation of the standard much easier as you do not need to convert between different data models.`}</p>
    <h3 {...{
      "id": "why-integrating-with-the-tomp-standard-is-a-smart-business-move"
    }}>{`Why Integrating with the TOMP Standard is a Smart Business Move`}</h3>
    <p>{`Integrating with the Shared Mobility API, built on the proven TOMP standard, opens doors to new customers and strengthens your
collaboration with public transport providers in key municipalities and cities. Entering a Maas-offering platform will significantly
increase your exposure to new customers by participating in a bigger marketplace.`}</p>
    <p>{`By aligning with this standard, your service becomes part of a cohesive mobility offering, making it easier for residents and visitors
to discover and use your solutions. Municipalities increasingly favor operators who integrate with shared mobility frameworks, as it
ensures better accessibility, reliability, and user experience for their citizens. Moreover, you position your business as a trusted
and forward-thinking partner, securing both market growth and long-term cooperation with public transport providers.`}</p>
    <p>{`In addition, as mobility trends evolve, the TOMP standard is actively maintained and updated to accommodate new technologies and use cases.
By integrating now, you prepare your service for continued relevance in the rapidly changing mobility landscape.`}</p>
    <h4 {...{
      "id": "why-the-tomp-standard-is-the-right-choice-and-what-it-offers"
    }}>{`Why the TOMP Standard is the Right Choice and What It Offers`}</h4>
    <ol>
      <li parentName="ol">{`Enhanced Interoperability: The TOMP standard is designed to ensure seamless communication between mobility operators and public
transport providers. This reduces technical barriers and simplifies integration, saving time and resources while ensuring your
service is ready for future scalability.`}</li>
      <li parentName="ol">{`Access to a Growing Network: By adopting the TOMP standard, your service becomes part of a unified ecosystem that connects operators,public
transport authorities, and end users. This increases visibility and access to new customer groups, hence a potential for top linegrowth.`}</li>
      <li parentName="ol">{`Streamlined Collaboration: The standard fosters closer collaboration with public transport companies, ensuring smoother partnerships and
alignment with local mobility strategies. This collaboration helps build trust and enhances your position in competitive markets.`}</li>
      <li parentName="ol">{`Regulatory Compliance: Municipalities and transport authorities often favor operators who adhere to established standards like TOMP when
issuing permits. This alignment can improve your chances of securing concessions.`}</li>
      <li parentName="ol">{`Improved User Experience: TOMP ensures that users can plan, book, and pay for services through unified systems, reducing friction and
increasing satisfaction. This makes your service more attractive to both new and existing customers.`}</li>
      <li parentName="ol">{`Future-Proofing Your Service: As mobility trends evolve, the TOMP standard is actively maintained and updated to accommodate new
technologies and use cases. By integrating now, you prepare your service for continued relevance in the rapidly changing mobility landscape.`}</li>
      <li parentName="ol">{`Sustainability Alignment: Many municipalities prioritize sustainable transport solutions. Integrating with the TOMP standard positions
your service as part of a sustainable ecosystem, enhancing your brand image and appeal to environmentally conscious users.`}</li>
    </ol>
    <h2 {...{
      "id": "our-reference-application"
    }}>{`Our reference application`}</h2>
    <p>{`Team shared mobility at Entur have created a open source reference application with all needed controllers, data classes and
services with test data as a Kotlin Spring Boot application. We have also written a guide in the README with information about
what kind of endpoints you as a Transport Operator need to implement. Since the TOMP-standard supports all kinds of mobilities,
and we (as of 20th August 2024) only supports micro-mobility, the amount of endpoints and response data is at a minimum.`}</p>
    <ul>
      <li parentName="ul">{`Application: `}<a parentName="li" {...{
          "href": "https://github.com/entur/shared-mobility-to-ref"
        }}>{`https://github.com/entur/shared-mobility-to-ref`}</a></li>
      <li parentName="ul">{`Swagger doc: `}<a parentName="li" {...{
          "href": "https://petstore.swagger.io/?url=https://api.dev.entur.io/api-docs/shared-mobility-to-ref"
        }}>{`https://petstore.swagger.io/?url=https://api.dev.entur.io/api-docs/shared-mobility-to-ref`}</a></li>
    </ul>
    <h2 {...{
      "id": "realtime-data"
    }}>{`Realtime data`}</h2>
    <p>{`Since all shared mobility operators in Norway are required by law to deliver realtime data to Entur, our team and clients uses
the `}<a parentName="p" {...{
        "href": "https://developer.entur.org/pages-mobility-docs-mobility-v2"
      }}>{`Mobility API`}</a>{` to receive information about available assets,
system information, vehicle types, pricing plans, system regions and geofencing zones. Because of this a lot of TOMP-endpoints
becomes redundant, which also makes the implementation of the standard easier for transport operators.`}</p>
    <h2 {...{
      "id": "implementation"
    }}>{`Implementation`}</h2>
    <p>{`As mentioned, we have created an open source project who works as a reference app on how to implement the TOMP API standard.
Take a look at the README.md in the `}<a parentName="p" {...{
        "href": "https://github.com/entur/shared-mobility-to-ref/blob/main/README.md"
      }}>{`shared-mobility-to-ref`}</a>{`
application for how to implement all the required endpoints.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      